import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { MouseEventHandler, ReactElement, useEffect, useReducer, useState } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../Hooks/UseGlobalContext';
import Cookies from 'js-cookie'
import { API_URL } from '../../Config/config';
import { Card, OverlayTrigger, Popover } from 'react-bootstrap';
import { IHandleToast } from '../../Types/IHandleToast'
import { _createReport } from '../../Services/API/reporting';
import { IoCloseOutline } from "react-icons/io5";
import ReportingControls from './ReportingControls';
import SpringAnimation from '../../Components/Common/SpringAnimation';

type TemplateVariable = {
    [key: string]: string;
}

type Props = {
    handleToast: (heading: string, message: string, type: string) => void
    handleCreateReport: (event: React.FormEvent<HTMLFormElement>) => void
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, templateVariableKey: string) => void
    handleDeleteInput: (i: number) => void
    inputState: TemplateVariable[]
}

const ReportingInputs: React.FC<Props> = (props) => {

    const popover = (
        <Popover id="popover-basic">
            {/* <Popover.Header as="h3">Files</Popover.Header> */}
            <Popover.Body>
                <i>Delete this input</i>
            </Popover.Body>
        </Popover>
    )

    return (
        <SpringAnimation animation="fadein">
            <Card id="card">
                <Card.Body>
                    <h5 className='quango'>Template Variables</h5>
                    <hr></hr>
                    <Form onSubmit={props.handleCreateReport} validated={false}>
                        {props.inputState.map((data: any, i: number) => {
                            let templateVariableKey: string = Object.keys(data)[0]
                            return (
                                <Row className="mb-3" controlId={`formBasic${templateVariableKey}`} key={i}>
                                    <Col lg={3}>
                                        <Form.Label><code>&#123;&#123;{templateVariableKey}&#125;&#125;</code></Form.Label>
                                    </Col>
                                    <Col lg={9}>
                                        <span className='d-flex align-items-center'>
                                            <Form.Control onChange={(event: React.ChangeEvent<HTMLInputElement>) => { props.handleChange(event, templateVariableKey) }} type="text" name={templateVariableKey.trim()} placeholder={`Enter ${templateVariableKey}`} autoComplete={templateVariableKey} />

                                            <OverlayTrigger trigger={["hover", "focus"]} placement={'right'} overlay={popover}>
                                                <span>

                                                    <IoCloseOutline style={{ cursor: 'pointer' }} size={25} onClick={() => props.handleDeleteInput(i)}></IoCloseOutline>
                                                </span>
                                            </OverlayTrigger>

                                        </span>
                                    </Col>
                                </Row>
                            )
                        })}
                        {props.inputState.length > 0 &&
                            <Button variant="dark" type="submit">
                                Create
                            </Button>
                        }
                    </Form>
                </Card.Body>
            </Card>
        </SpringAnimation >

    );

}
export default ReportingInputs;