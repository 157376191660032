import { Card, Col, Row, Badge } from 'react-bootstrap';
import { IHandleToast } from '../../Types/IHandleToast';
import { Spinner } from '../../Components/Common/Spinner';
import { useEffect, useState } from "react"
import { TemplateManagementTable } from '../../Components/TemplateManagement/TemplateManagementTable'
import Tabs from '../../Components/Common/Tabs';
import { _getDriveFiles } from '../../Services/API/drive-api';
import { IDriveFile } from '../../Types/IDriveFile';
import FileUpload from '../../Components/TemplateManagement/FileUpload';
import FileDelete from '../../Components/TemplateManagement/FileDelete';


const TemplateManagement: React.FC<IHandleToast> = (props) => {
    const [activeKey, setActiveKey] = useState("Upload")
    const [loadingTemplateTable, setLoadingTemplateTable] = useState(false)
    const [files, setFiles] = useState<IDriveFile[] | []>([])
    const [selectedFile, setSelectedFile] = useState<IDriveFile | undefined>(undefined);
    const [loadingIframe, setLoadingIframe] = useState<boolean | undefined>(true)
    const [tempFile, setTempFile] = useState<IDriveFile | null>(null);


    const handleTabSelect = (eventKey: string) => setActiveKey(eventKey);

    const fetchFiles = async () => {
        setLoadingTemplateTable(true)
        const response = await _getDriveFiles()
        setSelectedFile(undefined)
        const updated = response.data.map((f: any) => {
            f.disabled = false
            f.selected = false
            return f
        })
        setFiles([...updated])
        setTempFile(response.data[0])
        setLoadingTemplateTable(false)
    }

    useEffect(() => {
        fetchFiles()
    }, [])

    const handleFileSelectCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked === true) {
            setSelectedFile(files.find(f => f.id === e.target.id))
            const updated = files.map((f) => {
                if (f.id === e.target.id) {
                    f.disabled = false
                    f.selected = true
                } else {
                    f.disabled = true
                    f.selected = false
                }
                return f
            })
            setFiles([...updated])
        } else {
            setSelectedFile(undefined)
            const updated = files.map((f) => {
                f.disabled = false
                f.selected = false
                return f
            })
            setFiles([...updated])
        }
    }

    const file = selectedFile ? selectedFile.id : tempFile?.id

    return (
        <Row className="mb-5">
            <Col lg={8} className="mx-auto mb-5">
                {loadingTemplateTable ?
                    <Spinner loading={loadingTemplateTable} />
                    :
                    <TemplateManagementTable handleFileSelectCheckbox={handleFileSelectCheckbox} files={files} />
                }
            </Col>

            <Col lg={{ span: 5, offset: 2 }} className="mb-5">
                <Tabs handleTabSelect={handleTabSelect} activeKey={activeKey} tabs={['Upload', 'Delete']} />
                <Card id="card" className="tabs-card">
                    <Card.Body>
                        {activeKey === "Upload" &&
                            <FileUpload fetchFiles={fetchFiles} handleToast={props.handleToast} />
                        }
                        {activeKey === "Delete" &&
                            <FileDelete selectedFile={selectedFile} handleToast={props.handleToast} fetchFiles={fetchFiles} />
                        }
                    </Card.Body>
                </Card>
            </Col>
            {<Col lg={7} className="mx-auto mt-5 mb-5" style={selectedFile ? { display: 'block' } : { display: 'none' }}>
                <Spinner loading={loadingIframe} />
                <iframe onLoadStart={() => setLoadingIframe(true)} onLoad={() => setLoadingIframe(false)}
                    style={{ width: '100%', height: '900px', display: loadingIframe ? 'none' : 'block' }}
                    src={selectedFile ? `https://docs.google.com/document/d/${file}/edit?usp=sharing&ouid=103677032040719249278&rtpof=true&sd=true` : ''}>
                </iframe>
            </Col>}
        </Row>

    );
}

export default TemplateManagement;