import { Button, Container, Form, Row, Col, Card } from 'react-bootstrap';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../Hooks/UseGlobalContext';
import Cookies from 'js-cookie'
import { IHandleToast } from '../../Types/IHandleToast'
import SpringAnimation from '../../Components/Common/SpringAnimation';
import { _getUser, _updateUser } from '../../Services/API/user-api';
import { Spinner } from '../../Components/Common/Spinner';


const UserProfile: React.FC<IHandleToast> = (props) => {

    const { user, setUser } = useGlobalContext();
    const [firstName, setFirstName] = useState(user?.first_name);
    const [lastName, setLastName] = useState(user?.last_name);
    const [email, setEmail] = useState(user?.email);
    const [password, setPassword] = useState('');
    //checkbox
    const [showParticles, setShowParticles] = useState(true)
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate()
    // console.log(user)

    useEffect(() => {
        const fetchUser = async () => {
            setLoading(true)
            const _id = Cookies.get('_id')
            const response = await _getUser(_id)
            const user = response.data[0]
            setFirstName(user.first_name)
            setLastName(user.last_name)
            setEmail(user.email)
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            setShowParticles(user.user_settings.show_particles)
        }

        fetchUser()
    }, [])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            case 'firstName': {
                setFirstName(e.target.value)
                break;
            }
            case 'lastName': {
                setLastName(e.target.value)
                break;
            }
            case 'email': {
                setEmail(e.target.value)
                break;
            }
            case 'password': {
                setPassword(e.target.value)
                break;
            }
        }
    }

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowParticles((showParticles) => !showParticles)
        if (e.target.checked) {
            setShowParticles(true)
        } else {
            setShowParticles(false)
        }

    }

    const handleUpdateProfile = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            setLoading(true)
            await _updateUser(user?.user_id, { first_name: firstName, last_name: lastName, email, password, show_particles: showParticles })
            // navigate('/home', {
            //     state: {
            //         message: `New account created`
            //     }
            // })
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            props.handleToast('Accounts', 'Account Updated', 'Success')
        } catch (err) {
            console.log('use this err', err)
            return
        }
    }

    return (
        <Row className="mb-auto mt-5">
            {loading ? <Spinner loading={loading} /> :

                <Col lg={5} className="mx-auto">
                    <SpringAnimation animation="fadein">
                        <Card id="card">
                            <Card.Body>
                                <h5 className='quango'>Update Profile</h5>
                                <hr></hr>
                                <Form onSubmit={handleUpdateProfile} validated={false}>
                                    {/* <h1>fjkdshgdskjbdsklhgdks</h1> */}
                                    <Row className="mb-3">
                                        <Col lg={6}>
                                            <Form.Label>First Name</Form.Label>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Control value={firstName} onChange={handleChange} type="text" name="firstName" placeholder="Change firstname" autoComplete='email' />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col lg={6}>
                                            <Form.Label>Last Name</Form.Label>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Control value={lastName} onChange={handleChange} type="text" name="lastName" placeholder="Change lastname" autoComplete='lastname' />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col lg={6}>
                                            <Form.Label>Email Address</Form.Label>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Control value={email} onChange={handleChange} type="email" name="email" placeholder="Change email" autoComplete='email' />
                                        </Col>
                                    </Row>
                                    {/* <Row className="mb-3">
                                        <Col lg={6}>
                                            <Form.Label>Password</Form.Label>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Control onChange={handleChange} type="password" name="password" placeholder="Change password" autoComplete='current-password' />
                                        </Col>
                                    </Row> */}
                                    <h5 className='quango'>Settings</h5>
                                    <hr></hr>
                                    <Row className="mb-3">
                                        <Col lg={6}>
                                            <Form.Label>Show Particle Effect</Form.Label>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Check checked={showParticles} onChange={handleCheck} name="particles" type="checkbox" label={undefined} />
                                        </Col>
                                    </Row>
                                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    </Form.Group>
                                    {/* <div className="d-grid gap-2"></div> */}
                                    {/* <div className='d-flex align-items-center justify-content-center'></div> */}
                                    <Button variant="dark" type="submit" className="mt-1">
                                        Update Profile
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </SpringAnimation>
                </Col>
            }
        </Row>
    );

}
export default UserProfile;