import React, { useState } from 'react';
import { Card, Col, Form, Row, Button, FormControlProps } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { IHandleToast } from '../../Types/IHandleToast';
import { KeyboardEvent } from 'react';
import SpringAnimation from '../../Components/Common/SpringAnimation';

type Props = {
    handleCreateInput: (label: string) => void,
    inputState: any,
    handleToast: (heading: string, message: string, type: string) => void
}

const ReportingControls: React.FC<Props> = (props) => {
    const [show, setShow] = useState(false);
    const [templateVariable, setTemplateVariable] = useState('')
    const [errors, setErrors] = useState<string | undefined>(undefined)

    const handleClose = () => setShow(false);
    // const toggleShow = () => setShow((s) => !s);

    const handleCreateInput = (event: React.MouseEvent<HTMLButtonElement>) => {
        // for (let state of props.inputState) {
        //     if (state.label === templateVariable) {
        //         props.handleToast('Error', 'Template variable already exists', 'danger')
        //         return
        //     }
        // }
        // if (templateVariable === '') {
        //     props.handleToast('Error', 'Input name cannot be empty', 'danger')
        //     return
        // }
        props.handleCreateInput(templateVariable)
        setTemplateVariable('')
    }

    const handleKeyPressCreateInput = (event: KeyboardEvent<HTMLInputElement>) => {
        // if (event.key == 'Enter') {
        //     for (let state of props.inputState) {
        //         if (state.label === templateVariable) {
        //             props.handleToast('Error', 'Template variable already exists', 'danger')
        //             return
        //         }
        //     }
        //     if (templateVariable === '') {
        //         props.handleToast('Error', 'Input name cannot be empty', 'danger')
        //         return
        //     }
        props.handleCreateInput(templateVariable)
        setTemplateVariable('')
    }



    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTemplateVariable(e.target.value)
    }


    return (
        <SpringAnimation animation="fadein">
            <Card id="card">
                <Card.Body>
                    <h5 className='quango'>Controls</h5>
                    <hr></hr>
                    <Row>
                        <Col lg={10}>
                            <Form.Group className="mb-3">
                                <Form.Label>Add Template Variable</Form.Label>
                                <span className='d-flex'>

                                    <Button style={{ width: '40%' }} onClick={handleCreateInput} variant="dark" type="submit" className="me-3">
                                        Add Variable
                                    </Button>
                                    <Form.Control onChange={handleChange} value={templateVariable} type="text" name="input" placeholder="Choose variable name" autoComplete='input' />
                                </span>
                            </Form.Group>

                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Form.Group className="d-flex justify-content-between">

                                {/* <Button onClick={() => console.log('todo')} variant="dark" type="submit">
                                    Clear All
                                </Button> */}
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* <Button onClick={() => {
                        toggleShow()
                    }} variant="dark" type="submit">
                        Offcanvas
                    </Button> */}
                </Card.Body>
                <Offcanvas show={show} onHide={handleClose} {...{ scroll: true, backdrop: false }}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        Some text as placeholder. In real life you can have the elements you
                        have chosen. Like, text, images, lists, etc.
                    </Offcanvas.Body>
                </Offcanvas>
            </Card>
        </SpringAnimation>
    );
}

export default ReportingControls;