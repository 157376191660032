//components
import Home from './Pages/Home/Home';
import UseLocationExample from './Pages/UseLocationExample';
import UseParamsExample from "./Pages/UseParamsExample";
import Login from './Pages/Login/Login';
import AppContainer from './AppContainer';
import AppToast from './Components/Toast';
import ProtectedRoute from './Components/ProtectedRoutes/ProtectedRoute'
import PrivateRoute from './Components/ProtectedRoutes/PrivateRoute'
//react
import { Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useGlobalContext } from './Hooks/UseGlobalContext'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'
//packages
import axios from 'axios'
import Users from './Pages/Admin/Users';
import FileManagement from './Pages/FileManagement/FileManagement';
import TemplateManagement from './Pages/TemplateManagement/TemplateManagement';
import Help from './Pages/Help';
import CreateUser from './Pages/Admin/CreateUser';
import { _getUser } from './Services/API/user-api';
import Reporting from './Pages/Reporting/Reporting';
import UserProfile from './Pages/UserProfile/UserProfile';
import ReportTypeSelection from './Pages/ReportTypeSelection/ReportTypeSelection';

const App: React.FC = () => {
  const { user, setUser } = useGlobalContext()
  const navigate = useNavigate();
  const location = useLocation();

  const [showToast, setShowToast] = useState(false);
  const [heading, setHeading] = useState('');
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');

  const handleToast = ((heading: string, message: string, type: string) => {
    setHeading(heading)
    setType(type)
    setMessage(message)
    setShowToast(true)
  })

  const fetchUser = async (_id: string) => {
    try {
      return await _getUser(_id)
    } catch (err) {
      console.log('use this err', err)
      Cookies.remove('_id')
      Cookies.remove('_stay')
      return navigate('/login')
    }
  }

  const handleBootUser = () => {
    setUser(null)
    Cookies.remove('_id')
    Cookies.remove('_stay')
    return navigate('/login')
  }


  useEffect(() => {
    const checkUserState = async () => {
      const _id = Cookies.get('_id')
      const _stay = Cookies.get('_stay')
      console.log(_id, _stay)
      if (_stay === 'true' && _id) {
        let response = await fetchUser(_id)
        console.log(response)
        const currUser = response ? response.data[0] : null
        // console.log(currUser)
        if (currUser.logged_in) {
          setUser(currUser)
          return navigate(location.pathname)
        } else {
          return handleBootUser()
        }
      } else if (_stay === 'false' && _id) {
        return navigate(location.pathname)
      } else {
        return handleBootUser()
      }
    }

    checkUserState()
  }, [location.pathname, navigate, setUser])

  return (
    <div className="main-container">
      <AppToast heading={heading} message={message} type={type} showToast={showToast} setShowToast={setShowToast} />
      <Routes>
        <Route path="/" element={
          <Login handleToast={handleToast} />
        } />
        <Route path="/login" element={
          <Login handleToast={handleToast} />
        } />
        {/* Route Container */}
        <Route element={<AppContainer handleToast={handleToast} user={user} />}>
          <Route path="*" element={
            <Home />
          } />
          <Route path="/home" element={
            <ProtectedRoute user={user}>
              <Home />
            </ProtectedRoute>
          } />
          <Route path="/about" element={
            <ProtectedRoute user={user}>
              <UseLocationExample />
            </ProtectedRoute>
          } />
          <Route path="/profile" element={
            <ProtectedRoute user={user}>
              <UserProfile handleToast={handleToast} />
            </ProtectedRoute>
          } />
          <Route path="/files/files" element={
            <ProtectedRoute user={user}>
              <FileManagement handleToast={handleToast} />
            </ProtectedRoute>
          } />
          <Route path="/templates" element={
            <ProtectedRoute user={user}>
              <TemplateManagement handleToast={handleToast} />
            </ProtectedRoute>
          } />
          <Route path="/reporting" element={
            <ProtectedRoute user={user}>
              <Reporting handleToast={handleToast} />
            </ProtectedRoute>
          } />
          <Route path="/reporttype" element={
            <ProtectedRoute user={user}>
              <ReportTypeSelection handleToast={handleToast} />
            </ProtectedRoute>
          } />
          <Route path="/admin/users/create" element={
            <PrivateRoute user={user}>
              <CreateUser handleToast={handleToast} />
            </PrivateRoute>
          } />
          <Route path="/admin/users" element={
            <PrivateRoute user={user}>
              <Users />
            </PrivateRoute>
          } />
          <Route path="/help" element={
            <ProtectedRoute user={user}>
              <Help />
            </ProtectedRoute>
          } />
          {/* <Route path="/profile/:username" element={<ProtectedRoute user={user}><UseParamsExample /></ProtectedRoute>} /> */}
        </Route>
      </Routes>
    </div>
  );
}

export default App;

