import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, Outlet, NavLink } from 'react-router-dom';
import { IUser } from '../../Types/IUser';
import { useGlobalContext } from '../../Hooks/UseGlobalContext'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'
import axios from 'axios'
import moment from 'moment';
import { useEffect, useState } from 'react';
import { IoLogOutOutline } from 'react-icons/io5'
import { OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { API_URL } from '../../Config/config'

interface INav {
    handleToast: (heading: string, message: string, type: string) => void,
    user: IUser | null
}



const Header: React.FC<INav> = (props) => {
    const [showSettings, setShowSettings] = useState(false)
    const navigate = useNavigate()
    const { user, setUser } = useGlobalContext();
    const returnTime = (withSeconds: boolean): string => {
        const twentyFourHourTime = moment().format().slice(11).slice(0, -9);
        return `${moment().format('dddd').slice(0, 3)} 
        ${moment().format('ll').slice(4, 6)} 
            ${moment().format('ll').slice(0, 3)} 
            ${twentyFourHourTime}`
    }
    const [time, setTime] = useState(returnTime(false));
    const popover = (
        <Popover id="popover-basic">
            {/* <Popover.Header as="h3">Files</Popover.Header> */}
            <Popover.Body>
                <i>{user?.email}</i>
            </Popover.Body>
        </Popover>
    )

    const handleLogout = async () => {
        let response;
        try {
            response = await axios.post(`${API_URL}/auth/logout/`, { id: user?.user_id })
        } catch (err) {
            console.log('use this err', err)
            return
        }
        Cookies.remove('_id')
        Cookies.remove('_stay')
        setUser(null)
        navigate('/login')
        // props.handleToast('Logout', 'Successfully logged out', 'Success')
    }

    useEffect(() => {
        function handleTime() {
            const interval = setInterval(() => setTime(returnTime(false)), 1000);
            return () => {
                clearInterval(interval);
            };
        }
        function settingsDropdownOutsideClickClose() {
            document.addEventListener('click', (e: any) => {
                if (e.target.classList.length) {
                    if (e.target.classList.contains('dropdown-toggle') || e.target.classList.contains('dropdown-item')) {
                        return
                    } else {
                        setShowSettings(false)
                    }
                }
            })
        }
        handleTime()
        settingsDropdownOutsideClickClose()
    }, []);

    const NavItemsLeft = (
        <Nav className="me-auto">
            <NavLink className="nav-link quango" to="/home">Home</NavLink>
            <NavLink className="nav-link quango" to="/reporting">Reporting</NavLink>
        </Nav>
    )

    const NavItemsRight = (
        <Nav>
            {/* <NavLink className="nav-link quango" to="/files/files">Files</NavLink> */}
            <NavLink className="nav-link quango" to="/reporttype">Types</NavLink>
            <NavLink className="nav-link quango" to="/templates">Templates</NavLink>
        </Nav>
    )



    const handleShowSettings = () => {
        setShowSettings(prevState => !prevState)
    }
    const isActive = window.location.pathname.includes('/admin')

    const NavItemsDropdown = (
        <Nav className="me-3">
            <NavDropdown menuVariant="dark" title={'Settings'} active={isActive} onClick={handleShowSettings} show={showSettings} className="quango" id="collapsible-nav-dropdown">
                {user?.roles === 'admin' &&
                    <>
                        <Link className='dropdown-item quango' to="admin/users">
                            Users
                        </Link>
                        <Link className='dropdown-item quango' to="admin/users/create">
                            Create User
                        </Link>
                    </>
                }
                <Link className='dropdown-item quango' to="/profile">
                    Profile
                </Link>
                {/* <NavDropdown.Divider /> */}
            </NavDropdown>
        </Nav>
    )

    const NavItemsLogout = (
        <Nav>
            <time className="quango time me-3 my-2">{time}</time>
            {Boolean(user) &&
                <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={popover}>
                    <Nav.Link className="quango me-5" onClick={handleLogout}>Logout<IoLogOutOutline className='logout-icon' /></Nav.Link>
                </OverlayTrigger>
            }
        </Nav>
    )

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
            <Container>
                <Link className="nav-link quango me-5" to="/home"><Navbar.Brand><img src={'./favicon-16x16.png'} style={{ marginBottom: '5px' }} alt=""></img> Reporter</Navbar.Brand></Link>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    {NavItemsLeft}
                    {NavItemsRight}
                    {NavItemsDropdown}
                    {NavItemsLogout}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;