import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { MouseEventHandler, ReactElement, useEffect, useReducer, useState } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../Hooks/UseGlobalContext';
import Cookies from 'js-cookie'
import { API_URL } from '../../Config/config';
import { Card } from 'react-bootstrap';
import { IHandleToast } from '../../Types/IHandleToast'
import { _createReport } from '../../Services/API/reporting';
import { IoCloseOutline } from "react-icons/io5";
import ReportingControls from './ReportingControls';
import ReportingInputs from './ReportingInputs';

type templateVariable = {
    [key: string]: string;
}

const Reporting: React.FC<IHandleToast> = (props) => {

    const navigate = useNavigate()
    const { setUser } = useGlobalContext();
    const [inputState, setInputState] = useState<templateVariable[]>([])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, templateVariableKey: string) => {
        let copied = [...inputState]
        let index = inputState.findIndex((v: templateVariable) => Object.keys(v)[0] === templateVariableKey)
        copied[index][`${templateVariableKey}`] = event.target.value
        setInputState(copied)
    }

    console.log(inputState)

    const handleCreateInput = (label: string) => {
        const templateVariable: templateVariable = {}
        templateVariable[`${label}`] = ''
        setInputState([...inputState, templateVariable])
        props.handleToast('Success', `Template variable {{${label}}} created`, 'success')
    }
    console.log(inputState)

    const handleDeleteInput = (i: number) => {
        const copied = [...inputState]
        copied.splice(i, 1)
        setInputState(copied)
    }

    const handleCreateReport = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // console.log(inputState)
        let response;
        try {
            response = await _createReport(inputState)
        } catch (err) {
            console.log('use this err', err)
            return
        }
        navigate('/home', {
            state: {
                message: `New account created`
            }
        })
        props.handleToast('Success', 'Report Created', 'success')
    }

    return (
        <>
            <Row>
                <Col lg={5} className="mb-5 mx-auto">
                    <ReportingControls handleCreateInput={handleCreateInput} inputState={inputState} handleToast={props.handleToast} />
                </Col>
            </Row>
            <Row className="mb-5">
                <Col lg={5} className="mx-auto">
                    <ReportingInputs handleToast={props.handleToast} handleCreateReport={handleCreateReport} handleChange={handleChange} handleDeleteInput={handleDeleteInput} inputState={inputState} />
                </Col>
            </Row>

        </>
    );

}
export default Reporting;