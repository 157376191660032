import React, { useCallback, useState } from 'react'
import axios, { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../Hooks/UseGlobalContext'
import Cookies from 'js-cookie'
import { API_URL } from '../../Config/config'
import { Card, Col, Row, Form, Button } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import { IHandleToast } from '../../Types/IHandleToast'
import { Spinner } from '../../Components/Common/Spinner'
//@ts-ignore
import Particles from 'react-particles'
//@ts-ignore
import { loadFull } from 'tsparticles'
import { particlesOptionsLogin } from '../../Options/particlesOptionsLogin'
import { BiLockAlt } from 'react-icons/bi'
import { _login } from 'src/Services/API/auth-api'

const Login: React.FC<IHandleToast> = (props) => {
    const [stayLoggedIn, setStayLoggedIn] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { setUser } = useGlobalContext()

    const schema = yup.object().shape({
        email: yup.string().required().email().default('').label('Email'),
        password: yup.string().required().default('').label('Password'),
    })

    const particlesInit = useCallback(async (engine: any) => {
        // console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine)
    }, [])

    const particlesLoaded = useCallback(async (container: any) => {
        // await console.log(container);
    }, [])

    // const handleChange = (e: any) => {
    //     switch (e.target.type) {
    //         case 'email': {
    //             setEmail(e.target.value)
    //             break;
    //         }
    //         case 'password': {
    //             setPassword(e.target.value)
    //             break;
    //         }
    //     }
    // }

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStayLoggedIn((prevLoggedIn) => !prevLoggedIn)
    }

    const handleLogin = async (values: { email: string; password: string }) => {
        setLoading(true)
        let response
        try {
            response = await _login(values)
        } catch (err: any) {
            setLoading(false)
            // props.handleToast('Login', err.response?.data.message, 'Error')
            props.handleToast('Login', 'Bad request', 'Error')
            return
        }
        const id = response.data[0].user_id
        Cookies.set('_id', id, { expires: 10 })
        setUser(response.data[0])
        if (stayLoggedIn) {
            Cookies.set('_stay', 'true', { expires: 10 })
        } else {
            Cookies.set('_stay', 'false', { expires: 10 })
        }
        setTimeout(() => {
            setLoading(false)
        }, 1000)
        navigate('/home', {
            state: {
                user: response.data[0],
            },
        })
        props.handleToast('Success', 'Successfully logged in', 'success')
    }

    return (
        <>
            <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                //@ts-ignore
                options={particlesOptionsLogin}
                canvasClassName="particles-canvas more stronger"
                className="particles"
            />
            <Col
                lg={3}
                className={
                    loading ? 'mx-auto d-flex justify-content-center' : 'mx-auto'
                }
            >
                {loading ? (
                    <Spinner loading={loading} />
                ) : (
                    <Card id="card" className="login">
                        <Card.Body>
                            <Formik
                                validationSchema={schema}
                                onSubmit={(values) => {
                                    handleLogin(values)
                                }}
                                initialValues={{
                                    email: '',
                                    password: '',
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors,
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                isInvalid={!!errors.email}
                                                isValid={touched.email && !errors.email}
                                                value={values.email}
                                                onChange={handleChange}
                                                type="email"
                                                name="email"
                                                placeholder="Enter email"
                                                autoComplete="email"
                                            />
                                            <Form.Text className="text-muted">
                                                We'll never share your email with anyone else.
                                            </Form.Text>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicPassword"
                                        >
                                            <Form.Label>
                                                Password
                                                <BiLockAlt />
                                            </Form.Label>
                                            <Form.Control
                                                isInvalid={!!errors.password}
                                                isValid={touched.password && !errors.password}
                                                value={values.password}
                                                onChange={handleChange}
                                                type="password"
                                                name="password"
                                                placeholder="Password"
                                                autoComplete="current-password"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.password}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            className="my-4"
                                            controlId="formBasicCheckbox"
                                        >
                                            <Form.Check
                                                isInvalid={false}
                                                checked={stayLoggedIn}
                                                onChange={handleCheck}
                                                type="checkbox"
                                                label="Stay logged in"
                                            />
                                        </Form.Group>
                                        {/* <div className="d-grid gap-2">
                                            </div> */}
                                        <Button
                                            className="mb-3"
                                            style={{ width: '90px' }}
                                            disabled={
                                                Boolean(errors.password) || Boolean(errors.email)
                                            }
                                            variant="dark"
                                            type="submit"
                                        >
                                            Login
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </Card.Body>
                    </Card>
                )}
            </Col>
        </>
    )
}
export default Login
