import { useGlobalContext } from "../../Hooks/UseGlobalContext"
import { useLocation } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import Cookies from 'js-cookie'
import { Col, Row, } from 'react-bootstrap';
import React from 'react';
import ReportingLineChart from '../../Components/Home/ReportsLineChart'
import StorageQuotaPieChart from '../../Components/Home/StorageQuotaPieChart'
import YouTubeCard from '../../Components/Home/YouTubeCard'
import ModalCentered from 'src/Components/Modal/ModalCentered'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const Home: React.FC = (props) => {
    // const navigate = useNavigate();
    // const location = useLocation();
    const { user, setUser } = useGlobalContext()
    const [showModal, setShowModal] = useState(true)
    const [stopShowingHomeDialog, setStopShowingHomeDialog] = useState(false)

    const onHide = () => {
        console.log('HELLO WORLD')
        if (stopShowingHomeDialog === true) {
            setShowModal(!showModal)
            localStorage.setItem('stopShowingHomeDialog', '1')
        } else {
            setShowModal(!showModal)
        }
    }

    const handleShowDialogChange = () => {
        setStopShowingHomeDialog(!stopShowingHomeDialog)
    }

    useEffect(() => {
        const stopShowingHomeDialog = localStorage.getItem('stopShowingHomeDialog')
        stopShowingHomeDialog ? setShowModal(false) : setShowModal(true)
    }, [])


    return (
        <>
            <Row className="mb-5">
                <Col lg={2}></Col>
                <Col lg={2} className="">
                    <StorageQuotaPieChart />
                </Col >
                <Col lg={6} className="">
                    <ReportingLineChart />
                </Col>
            </Row>
            {user?.roles === 'admin' &&
                <Row className="mb-5">
                    <Col lg={2}></Col>
                    <Col lg={2} className="me-3"></Col>
                    <Col lg={6} className="">
                        <YouTubeCard />
                    </Col>
                </Row >
            }
            <ModalCentered onHide={onHide} show={showModal} heading={"Welcome"}>
                <>
                    <Row className="mb-3">
                        <Col lg={12}>
                            <img style={{ borderRadius: '25px' }} src="https://wompampsupport.azureedge.net/fetchimage?siteId=7575&v=2&jpgQuality=100&width=700&url=https%3A%2F%2Fi.kym-cdn.com%2Fentries%2Ficons%2Ffacebook%2F000%2F013%2F564%2Fdoge.jpg" />
                        </Col>
                    </Row>
                    <Row className="mb-0">
                        <Col lg={1}>
                            <Form.Check checked={stopShowingHomeDialog} onChange={handleShowDialogChange} name="particles" type="checkbox" label={undefined} />
                        </Col>
                        <Col lg={4}>
                            <Form.Label>Don't show this message again</Form.Label>
                        </Col>
                        <Col lg={6}></Col>
                    </Row>
                </>
                {/* <InputGroup>
                    <InputGroup.Radio aria-label="Radio button for following text input" />
                    <Form.Control aria-label="Text input with radio button" />
                </InputGroup> */}
            </ModalCentered>
        </>
    )
}


export default Home